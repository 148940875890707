import React, { Children, useState, useEffect } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactPause"
import { TextInput, Button, DatePicker, Select } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"
import { ContactQuery_site_siteMetadata_contact } from "../pages/__generated__/ContactQuery"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    address: yup.string().required(`お客様のご住所を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Sdate: yup.date().required(`お休み開始日を指定してください`),
    start: yup.string().required(`朝刊か夕刊どちらかを選択してください`),
    Edate: yup
        .date()
        .when(
            "Sdate",
            (Sdate, schema) =>
                Sdate &&
                schema.min(Sdate, "開始日よりも後の日付を選択してください")
        )
        .required(`お休み終了日を指定してください`),
    end: yup.string().required(`朝刊か夕刊どちらかを選択してください`),
    keep: yup.string().required(`どちらかを選択してください`),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    address: string
    telephone: string
    email: string
    Sdate: string
    start: string
    Edate: string
    end: string
    keep: string
    message: string
    type: string
    store: string
    sendmail: string
    tel: string
    storeAddress: string
    code: string
}

const Pause: React.FC<{
    data: ContactQuery_site_siteMetadata_contact
}> = ({ data }) => {
    const { errors, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    useEffect(() => {
        methods.setValue("type", "Pause")
        methods.setValue("store", "ニュースサービス日経本郷御茶ノ水")
        methods.setValue("sendmail", data.mail)
        methods.setValue("tel", data.phone)
        methods.setValue("storeAddress", data.address)
        methods.setValue("code", data.code)
    })
    const submit = values => {
        values.Sdate = dateFormat(values.Sdate, "isoDate")
        values.Edate = dateFormat(values.Edate, "isoDate")

        const GATEWAY_URL =
            "https://8ru9wphnlb.execute-api.ap-northeast-1.amazonaws.com/SendMail"
        trackPromise(
            fetch(GATEWAY_URL, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify(values),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
                .then(response => {
                    if (response.ok) {
                        navigate(`/thanks`)
                    }
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods}>
            <h4 className="text-center text-2xl text-color-4">
                一時配達休止のご連絡
            </h4>

            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <input type="hidden" name="type" ref={methods.register} />
                <input type="hidden" name="store" ref={methods.register} />
                <input type="hidden" name="sendmail" ref={methods.register} />
                <input type="hidden" name="tel" ref={methods.register} />
                <input
                    type="hidden"
                    name="storeAddress"
                    ref={methods.register}
                />
                <input type="hidden" name="code" ref={methods.register} />
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <TextInput
                    label="ご住所"
                    name="address"
                    footer={errors.address?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <DatePicker
                    label="いつから休止しますか？"
                    name="Sdate"
                    type="date"
                    min={new Date()}
                    footer={errors.Sdate?.message}
                />
                <Select
                    label="朝刊と夕刊どちらからですか？"
                    name="start"
                    footer={errors.start?.message}
                    value={[
                        { id: 1, value: "朝刊から" },
                        { id: 2, value: "夕刊から" },
                    ]}
                />
                <DatePicker
                    label="いつまで休止しますか？"
                    name="Edate"
                    type="date"
                    min={
                        methods.getValues("Sdate")
                            ? methods.getValues("Sdate")
                            : new Date()
                    }
                    footer={errors.Edate?.message}
                />
                <Select
                    label="朝刊と夕刊どちらまでですか？"
                    name="end"
                    footer={errors.end?.message}
                    value={[
                        { id: 1, value: "朝刊まで" },
                        { id: 2, value: "夕刊まで" },
                    ]}
                />
                <Select
                    label="休止中の新聞の取り置きについて"
                    name="keep"
                    footer={errors.keep?.message}
                    value={[
                        {
                            id: 1,
                            value: "休み中の新聞は不要",
                        },
                        {
                            id: 2,
                            value: "休み明けにまとめて届ける",
                        },
                    ]}
                />
                <TextInput
                    label="通信欄"
                    name="message"
                    footer={""}
                    placeholder={"例）日経新聞は取り置き、MJは取り置き不要です"}
                />
                <p>
                    複数紙をご購読の場合、特にご指定がなければすべての新聞を同じ期間、休止やお取り置き致します。
                    <br />
                    もし、個別に変更がございましたら通信欄にご記入願います
                </p>
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Pause
